import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../app/store";
import {
  OrderGroup,
  ProgramRow,
  SnackBarTemplate,
  states,
} from "../../common/resources/bulkOrders.types";
import { bulkOrdersApi } from "./bulkOrders.service";

export type BulkOrdersState = {
  snackBar: SnackBarTemplate;
  groupOrders: OrderGroup[];
  selectedProgram: ProgramRow;
  states: states[];
};

const initialState: BulkOrdersState = {
  snackBar: {
    open: false,
    title: null,
    subTitle: null,
    severity: "info",
  },
  groupOrders: [],
  selectedProgram: {
    program_id: "",
    program_sponsor: "",
    workflow: "",
    total_members: 0,
    total_opted_in: 0,
    opt_out: false,
    created_at: null,
    expiration: null,
    send_outreach: false,
    results_outreach: false,
    support_individual_interactions: false,
  },
  states: [],
};

export const bulkOrdersSlice = createSlice({
  name: "bulkOrders",
  initialState,
  reducers: {
    setSnackBar: (state, action: PayloadAction<SnackBarTemplate>) => {
      state.snackBar = action.payload;
    },
    setSelectedProgram: (state, action: PayloadAction<ProgramRow>) => {
      state.selectedProgram = action.payload;
    },
    resetState: (state) => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        bulkOrdersApi.endpoints.createProgram.matchFulfilled,
        (state) => {
          state.snackBar = {
            open: true,
            severity: "success",
            title: "ui.snackbar.success",
            subTitle: "bulkOrders.programCreation.successSub",
          };
        }
      )
      .addMatcher(
        bulkOrdersApi.endpoints.createProgram.matchRejected,
        (state) => {
          state.snackBar = {
            open: true,
            severity: "error",
            title: "ui.snackbar.error",
            subTitle: "bulkOrders.programCreation.errorSub",
          };
        }
      )

      .addMatcher(
        bulkOrdersApi.endpoints.createOrderGroup.matchFulfilled,
        (state) => {
          state.snackBar = {
            open: true,
            severity: "success",
            title: "ui.snackbar.success",
            subTitle: "bulkOrders.programCreation.orderGroupSuccessSub",
          };
        }
      )
      .addMatcher(
        bulkOrdersApi.endpoints.createOrderGroup.matchRejected,
        (state, action) => {
          state.snackBar = {
            open: true,
            severity: "error",
            title: "ui.snackbar.error",
            subTitle:
              action.payload?.data === "invalid status code"
                ? "bulkOrders.programCreation.orderGroupErrorSub"
                : "bulkOrders.programCreation.errorSub",
          };
        }
      )
      .addMatcher(
        bulkOrdersApi.endpoints.submitOrders.matchFulfilled,
        (state) => {
          state.snackBar = {
            open: true,
            severity: "success",
            title: "ui.snackbar.success",
            subTitle: "bulkOrders.submitOrders.submitted",
          };
        }
      )
      .addMatcher(
        bulkOrdersApi.endpoints.submitOrders.matchRejected,
        (state) => {
          state.snackBar = {
            open: true,
            severity: "error",
            title: "ui.snackbar.success",
            subTitle: "bulkOrders.programCreation.errorSub",
          };
        }
      )
      .addMatcher(bulkOrdersApi.endpoints.CSVFetch.matchFulfilled, (state) => {
        state.snackBar = {
          open: true,
          severity: "success",
          title: "ui.snackbar.success",
          subTitle: "bulkOrders.CSVFetch.successSub",
        };
      })
      .addMatcher(bulkOrdersApi.endpoints.CSVFetch.matchRejected, (state) => {
        state.snackBar = {
          open: true,
          severity: "error",
          title: "ui.snackbar.error",
          subTitle: "bulkOrders.CSVFetch.errorSub",
        };
      })
      .addMatcher(
        bulkOrdersApi.endpoints.getStates.matchFulfilled,
        (state, action) => {
          const sortedPayload = action.payload.collection.sort((a, b) =>
            a.state > b.state ? 1 : -1
          );
          state.states = sortedPayload;
        }
      )
      .addMatcher(
        bulkOrdersApi.endpoints.getOrderGroups.matchFulfilled,
        (state, action) => {
          state.groupOrders = action.payload.collection;
        }
      )
      .addMatcher(
        bulkOrdersApi.endpoints.getOrderGroups.matchRejected,
        (state) => {
          state.snackBar = {
            open: true,
            severity: "error",
            title: "ui.snackbar.error",
            subTitle: "bulkOrders.programCreation.errorSub",
          };
        }
      )
      .addMatcher(
        bulkOrdersApi.endpoints.updateProviderInformation.matchFulfilled,
        (state) => {
          state.snackBar = {
            open: true,
            severity: "success",
            title: "ui.snackbar.success",
            subTitle: "bulkOrders.providerInfoModal.successSub",
          };
        }
      )
      .addMatcher(
        bulkOrdersApi.endpoints.updateProviderInformation.matchRejected,
        (state) => {
          state.snackBar = {
            open: true,
            severity: "error",
            title: "ui.snackbar.error",
            subTitle: "bulkOrders.providerInfoModal.errorSub",
          };
        }
      )
      .addMatcher(
        bulkOrdersApi.endpoints.deletedOrder.matchFulfilled,
        (state) => {
          state.snackBar = {
            open: true,
            severity: "success",
            title: "ui.snackbar.success",
            subTitle: "bulkOrders.programDetails.deleteOrdersModal.success",
          };
        }
      )
      .addMatcher(
        bulkOrdersApi.endpoints.deletedOrder.matchRejected,
        (state) => {
          state.snackBar = {
            open: true,
            severity: "error",
            title: "ui.snackbar.error",
            subTitle: "bulkOrders.programDetails.deleteOrdersModal.error",
          };
        }
      );
  },
});

export default bulkOrdersSlice.reducer;

export const selectSnackBar = (state: RootState) => state.bulkOrders.snackBar;
export const selectGroupOrders = (state: RootState) =>
  state.bulkOrders.groupOrders;
export const selectSelectedProgram = (state: RootState): ProgramRow =>
  state.bulkOrders.selectedProgram;
export const selectStates = (state: RootState) => state.bulkOrders.states;

export const { setSnackBar, setSelectedProgram, resetState } =
  bulkOrdersSlice.actions;
