import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { RootState } from "../../app/store";
import {
  InsuranceRecord,
  MetricsReport,
} from "../../common/resources/exasAdmin.types";
import {
  FollowUpReportRow,
  ProviderReportRow,
} from "../../common/resources/reporting.types";

export const exactSciencesApi = createApi({
  reducerPath: "exactSciencesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_EXAS_ADMIN_API,
    prepareHeaders: (headers, { getState }) => {
      headers.set("Content-Type", "application/json");
      const state = getState() as RootState;
      if (state.user.accessToken) {
        headers.append("Authorization", `Bearer ${state.user.accessToken}`);
      }
      return headers;
    },
  }),
  tagTypes: ["FollowUps", "Report", "MemberInsurance"],
  endpoints: (builder) => ({
    fetchFollowUps: builder.query<FollowUpReportRow[], string>({
      query: () => ({
        url: "/communication-request/follow-up",
        method: "GET",
      }),
      providesTags: ["FollowUps"],
      transformResponse: (response: { collection: FollowUpReportRow[] }) => {
        return response.collection;
      },
    }),
    updateFollowup: builder.mutation<
      { patientId: string; orderId: string },
      { patientId: string; orderId: string }
    >({
      query: (body) => ({
        url: `/communication-request/follow-up`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["FollowUps"],
    }),
    fetchOrderReport: builder.query<any[], string>({
      query: (reportType) => ({
        url: "/report/order",
        params: { type: reportType },
        method: "GET",
      }),
      providesTags: (_result, _error, arg) => {
        return [{ type: "Report", id: arg }];
      },
      transformResponse: (resp: { collection: any[] }, _meta, arg) => {
        // These report types come back as different shapes and therefore must be 'any' typed
        if (arg === "day") {
          return resp.collection.map((elem, index) => {
            const date = new Date(elem.Year, elem.Month - 1, elem.DayOfMonth); // 2009-11-10
            const month = date.toLocaleString("default", { month: "long" });
            return {
              id: index,
              Date: date,
              MonthString: month,
              ...elem,
            };
          });
        }
        return resp.collection.map((elem, index) => {
          return {
            id: index,
            ...elem,
          };
        });
      },
    }),
    fetchResultStatusReport: builder.query<
      { Count: number; Interval: number; Result: string }[],
      { year: string; month: string }
    >({
      query: (queryParams) => ({
        url: "/report/result-status",
        method: "GET",
        params: queryParams,
      }),
      providesTags: (_result, _error, arg) => {
        return [
          { type: "Report", id: `result-status-${arg.year}-${arg.month}` },
        ];
      },
      transformResponse: (response: {
        collection: { Count: number; Interval: number; Result: string }[];
      }) => {
        return response.collection;
      },
    }),
    fetchOrderStatusReport: builder.query<
      MetricsReport,
      { year: string; month: string }
    >({
      query: (queryParams) => ({
        url: "/report/order-status",
        method: "GET",
        params: queryParams,
      }),
      providesTags: (_result, _error, arg) => {
        return [
          { type: "Report", id: `order-status-${arg.year}-${arg.month}` },
        ];
      },
    }),
    fetchOutreachStatus: builder.query<
      {
        Late: number;
        OnTime: number;
      },
      { year: string; month: string }
    >({
      query: (queryParams) => ({
        url: "/report/outreach-status",
        method: "GET",
        params: queryParams,
      }),
      providesTags: (_result, _error, arg) => {
        return [
          { type: "Report", id: `outreach-status-${arg.year}-${arg.month}` },
        ];
      },
    }),
    fetchMailCount: builder.query<
      { mailCount: number },
      { year: string; month: string }
    >({
      query: (queryParams) => ({
        url: "/report/mail-count",
        method: "GET",
        params: queryParams,
      }),
      providesTags: (_result, _error, arg) => {
        return [{ type: "Report", id: `mail-count-${arg.year}-${arg.month}` }];
      },
    }),
    fetchProviderResponse: builder.query<
      ProviderReportRow[],
      { year: string; month: string }
    >({
      query: (queryParams) => ({
        url: "/report/provider-response",
        method: "GET",
        params: queryParams,
      }),
      providesTags: (_result, _error, arg) => {
        return [
          { type: "Report", id: `provider-response-${arg.year}-${arg.month}` },
        ];
      },
      transformResponse: (response: { collection: ProviderReportRow[] }) => {
        return response.collection;
      },
    }),
    fetchInsuranceByMemberId: builder.query<InsuranceRecord[], string>({
      query: (memberId) => ({
        url: `/member/${memberId}/insurance`,
        method: "GET",
      }),
      providesTags: (_result, _error, arg) => {
        return [{ type: "MemberInsurance", id: arg }];
      },
      transformResponse: (response: { collection: InsuranceRecord[] }) => {
        const newResponse = response.collection.map((elem) => {
          return {
            company_name: elem.company_name.replaceAll('"', ""),
            coverage_type: elem.coverage_type.replaceAll('"', ""),
            plan_name: elem.plan_name.replaceAll('"', ""),
            vca_member_id: elem.vca_member_id.replaceAll('"', ""),
          };
        });
        return newResponse;
      },
    }),
  }),
});

export const {
  useFetchFollowUpsQuery,
  useFetchMailCountQuery,
  useFetchOrderStatusReportQuery,
  useFetchOutreachStatusQuery,
  useFetchOrderReportQuery,
  useFetchProviderResponseQuery,
  useFetchResultStatusReportQuery,
  useLazyFetchInsuranceByMemberIdQuery,
  useUpdateFollowupMutation,
} = exactSciencesApi;
