import {
  faBriefcase,
  faCalendar,
  faFileCsv,
  faList,
  faNotesMedical,
  faPaperPlaneTop,
  faTrash,
  faUserDoctor,
  faUsers,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  Card,
  Chip,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";

import { useAppSelector } from "../../app/hooks";
import CustomSnackbar from "../../common/components/CustomSnackBar/CustomSnackBar";
import {
  OrderGroup,
  ProviderInformationModel,
} from "../../common/resources/bulkOrders.types";
import { theme } from "../../common/theme/theme";
import {
  formatDate,
  formatExpirationDisplayDate,
} from "../../common/utils/formatDate";
import { FeatureFlag } from "../../config/flags.config";
import {
  selectGroupOrders,
  selectSelectedProgram,
  selectSnackBar,
  selectStates,
  setSnackBar,
} from "../../slices/bulkOrders/bulkOrders";
import {
  useGetOrderGroupsQuery,
  useGetStatesQuery,
  useLazyCSVFetchQuery,
} from "../../slices/bulkOrders/bulkOrders.service";
import OrderGroupConfirmationModal from "./OrderGroupConfirmationModal";
import OrderGroupConfirmDeleteModal from "./OrderGroupConfirmDeleteModal";
import { OrderGroupModal } from "./OrderGroupModal";
import ProviderInformationModal from "./ProviderInformationModal";

const ProgramDetails: React.FC = () => {
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState<boolean>(false);
  const [providerInformation, setProviderInformation] = useState<{
    open: boolean;
    readOnly: boolean;
    orderGroupID: string;
    providerInfo?: ProviderInformationModel;
  }>({
    open: false,
    readOnly: false,
    orderGroupID: "",
  });
  const handleProviderModalClose = () =>
    setProviderInformation({
      open: false,
      readOnly: false,
      orderGroupID: "",
    });
  const snackBar = useAppSelector(selectSnackBar);
  const groupOrders = useAppSelector(selectGroupOrders);
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(true);
  const [CSVFetch] = useLazyCSVFetchQuery();
  const selectedProgram = useAppSelector(selectSelectedProgram);
  const stateList = useAppSelector(selectStates);

  useGetStatesQuery({ clientID: selectedProgram.program_id });
  useGetOrderGroupsQuery({ clientID: selectedProgram.program_id });

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const ExpandMore = styled((props: any) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const style = {
    display: "flex",
    alignItems: "center",
  };

  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [groupOrderID, setGroupOrderID] = useState<string>("");

  const openProviderInformation = (
    orderGroupID: string,
    isSubmitted: boolean,
    savedProvider?: ProviderInformationModel
  ) => {
    if (savedProvider) {
      const {
        approver_first_name,
        approver_last_name,
        approver_phone_number,
        approver_npi,
      } = savedProvider;
      setProviderInformation({
        open: true,
        readOnly: isSubmitted,
        orderGroupID,
        providerInfo: {
          approver_first_name,
          approver_last_name,
          approver_phone_number,
          approver_npi,
        },
      });
    } else {
      setProviderInformation({
        orderGroupID,
        open: true,
        readOnly: false,
      });
    }
  };

  return (
    <Box sx={{ textAlign: "left" }}>
      <CustomSnackbar
        open={Boolean(snackBar.open)}
        autoHideDuration={5000}
        onClose={() => dispatch(setSnackBar({ ...snackBar, open: false }))}
        title={snackBar.title ? formatMessage({ id: snackBar.title }) : ""}
        subTitle={
          snackBar.subTitle ? formatMessage({ id: snackBar.subTitle }) : ""
        }
        severity={snackBar.severity}
      />
      <ProviderInformationModal
        orderGroupID={providerInformation.orderGroupID}
        providerInfo={providerInformation.providerInfo}
        isOpen={providerInformation.open}
        isReadOnly={providerInformation.readOnly}
        handleClose={handleProviderModalClose}
      />
      <OrderGroupConfirmationModal
        isOpen={confirmModalOpen}
        setModalOpen={setConfirmModalOpen}
        selectedProgramID={selectedProgram.program_id}
        groupOrderID={groupOrderID}
      />
      <OrderGroupConfirmDeleteModal
        isOpen={deleteModalOpen}
        setModalOpen={setDeleteModalOpen}
        selectedProgramID={selectedProgram.program_id}
        groupOrderID={groupOrderID}
      />
      <Card sx={{ p: 4 }}>
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold" }}
        >
          [{selectedProgram.program_id}]
          {formatMessage({
            id: "bulkOrders.createOrderGroup.details",
          })}
        </Typography>
        <Stack
          sx={{ m: 5, justifyContent: "space-between", justifyItems: "center" }}
          direction="row"
          spacing={2}
          divider={
            <Divider
              orientation="vertical"
              flexItem
            />
          }
        >
          <Grid item>
            <Grid sx={{ display: "flex" }}>
              <Typography
                variant="h6"
                sx={style}
              >
                <FontAwesomeIcon
                  style={{ marginRight: 5 }}
                  icon={faBriefcase}
                />
                {formatMessage({
                  id: "bulkOrders.programDetails.sponsor",
                })}
              </Typography>
            </Grid>
            {selectedProgram.program_sponsor}
          </Grid>
          <Grid item>
            <Grid sx={{ display: "flex" }}>
              <Typography
                variant="h6"
                sx={style}
              >
                <FontAwesomeIcon
                  style={{ marginRight: 5 }}
                  icon={faList}
                />
                {formatMessage({
                  id: "bulkOrders.programDetails.type",
                })}
              </Typography>
            </Grid>
            {selectedProgram.workflow}{" "}
            {selectedProgram.support_individual_interactions
              ? "Single"
              : "Bulk"}{" "}
            {selectedProgram.opt_out ? "Opt Out" : "Opt In"}
          </Grid>
          <Grid item>
            <Grid sx={{ display: "flex" }}>
              <Typography
                variant="h6"
                sx={style}
              >
                <FontAwesomeIcon
                  style={{ marginRight: 5 }}
                  icon={faCalendar}
                />
                {formatMessage({
                  id: "bulkOrders.programDetails.date",
                })}
              </Typography>
            </Grid>
            <Grid
              item
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography sx={{ justifyContent: "flex-start", marginRight: 2 }}>
                {formatMessage({
                  id: "bulkOrders.programDetails.start",
                })}
              </Typography>
              <Typography sx={{ justifyContent: "flex-end", marginLeft: 2 }}>
                {formatDate(selectedProgram.created_at?.toString() ?? "")}
              </Typography>
            </Grid>
            <Grid
              item
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography sx={{ justifyContent: "flex-start", marginRight: 2 }}>
                {formatMessage({
                  id: "bulkOrders.programDetails.end",
                })}
              </Typography>
              <Typography sx={{ justifyContent: "flex-end", marginLeft: 2 }}>
                {formatExpirationDisplayDate(
                  selectedProgram.expiration?.toString() ?? ""
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid sx={{ display: "flex" }}>
              <Typography
                variant="h6"
                sx={style}
              >
                <FontAwesomeIcon
                  style={{ marginRight: 5 }}
                  icon={faUsers}
                />
                {formatMessage({
                  id: "bulkOrders.programDetails.members",
                })}
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "space-between",
                whiteSpace: "nowrap",
                marginBottom: 1,
              }}
            >
              <Typography sx={{ justifyContent: "flex-start", marginRight: 2 }}>
                {formatMessage({
                  id: "bulkOrders.programDetails.optedIn",
                })}
              </Typography>
              <Chip
                label={selectedProgram.total_opted_in}
                sx={{
                  justifyContent: "center",
                  backgroundColor: theme.palette.primary.light,
                  color: theme.palette.primary.dark,
                }}
              />
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "space-between",
                whiteSpace: "nowrap",
              }}
            >
              <Typography sx={{ justifyContent: "flex-start", marginRight: 2 }}>
                {formatMessage({
                  id: "bulkOrders.programDetails.optedOut",
                })}
              </Typography>
              <Chip
                label={
                  selectedProgram.total_members - selectedProgram.total_opted_in
                }
                sx={{
                  color: theme.palette.primary.dark,
                  justifyContent: "center",
                }}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Grid sx={{ display: "flex" }}>
              <Typography
                variant="h6"
                sx={style}
              >
                <FontAwesomeIcon
                  style={{ marginRight: 5 }}
                  icon={faPaperPlaneTop}
                />
                {formatMessage({
                  id: "bulkOrders.programDetails.initialOutreachRequired",
                })}
              </Typography>
            </Grid>
            <Grid sx={{ display: "flex", justifyContent: "center" }}>
              <Chip
                label={selectedProgram.send_outreach ? "Yes" : "No"}
                sx={{
                  justifyContent: "center",
                  backgroundColor: selectedProgram.send_outreach
                    ? theme.palette.info.light
                    : "",
                }}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Grid sx={{ display: "flex" }}>
              <Typography
                variant="h6"
                sx={style}
              >
                <FontAwesomeIcon
                  style={{ marginRight: 5 }}
                  icon={faNotesMedical}
                />
                {formatMessage({
                  id: "bulkOrders.programDetails.providerResultsSharing",
                })}
              </Typography>
            </Grid>
            <Grid sx={{ display: "flex", justifyContent: "center" }}>
              <Chip
                label={selectedProgram.results_outreach ? "Yes" : "No"}
                sx={{
                  backgroundColor: selectedProgram.results_outreach
                    ? theme.palette.info.light
                    : "",
                }}
              />
            </Grid>
          </Grid>
        </Stack>
      </Card>
      <Card sx={{ px: 2, py: 2, my: 2 }}>
        <Grid
          sx={{ display: "flex", justifyContent: "space-between" }}
          container
        >
          <Typography variant="subtitle1">
            {formatMessage({
              id: "bulkOrders.createOrderGroup.orderGroup",
            })}
          </Typography>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Grid>
        <Collapse
          in={expanded}
          timeout="auto"
          unmountOnExit
        >
          {groupOrders.length === 0 ? (
            <Typography variant="h6">
              {formatMessage({
                id: "bulkOrders.createOrderGroup.noOrderGroup",
              })}
            </Typography>
          ) : null}
          <Grid
            sx={{
              display: "flex",
              justifyContent: groupOrders.length === 0 ? "center" : "flex-end",
            }}
          >
            <Button
              disabled={selectedProgram.total_opted_in === 0}
              sx={{ mt: 2, mb: 2 }}
              startIcon={<AddIcon />}
              variant="contained"
              onClick={() => setOpen(true)}
            >
              {formatMessage({
                id: "bulkOrders.createOrderGroup.title",
              })}
            </Button>
          </Grid>
          <Grid>
            {groupOrders.map((groupOrder: OrderGroup) => (
              <Card
                key={groupOrder.id}
                sx={{
                  p: 4,
                  my: 2,
                  borderRadius: "15px",
                  backgroundColor: theme.palette.primary.contrastText,
                }}
              >
                <Grid sx={{ display: "flex" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      mx: 2,
                      display: "flex",
                      color: "#342575",
                      fontWeight: "bold",
                    }}
                  >
                    {`${formatMessage({
                      id: "bulkOrders.programDetails.orderGroup",
                    })} `}
                    [{groupOrder.id}]
                  </Typography>
                  <Chip
                    sx={{
                      display: "flex",
                      backgroundColor: theme.palette.primary.light,
                      color: theme.palette.primary.dark,
                    }}
                    label={groupOrder.member_count + " Members"}
                  />
                  <Grid sx={{ display: "flex", marginLeft: "auto" }}>
                    <Button
                      onClick={() => {
                        CSVFetch({
                          orderGroupId: groupOrder.id,
                          programId: selectedProgram.program_id,
                        });
                      }}
                      variant="outlined"
                      disabled={
                        !groupOrder.src_url.Valid ||
                        !groupOrder.approver_npi.Valid
                      }
                      sx={{
                        backgroundColor: theme.palette.common.white,
                        color: theme.palette.common.black,
                        mx: 1,
                        "&:hover": {
                          backgroundColor: theme.palette.common.white,
                        },
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ marginRight: 5 }}
                        icon={faFileCsv}
                      />
                      {formatMessage({
                        id: "bulkOrders.programDetails.downloadCSV",
                      })}
                    </Button>
                    <Button
                      onClick={() => {
                        const {
                          id,
                          approver_first_name,
                          approver_last_name,
                          approver_phone_number,
                          approver_npi,
                          completed,
                        } = groupOrder;
                        if (
                          approver_first_name.String &&
                          approver_last_name.String &&
                          approver_npi.String
                        ) {
                          openProviderInformation(id, completed, {
                            approver_first_name: approver_first_name.String,
                            approver_last_name: approver_last_name.String,
                            approver_phone_number: approver_phone_number.String,
                            approver_npi: approver_npi.String,
                          });
                        } else {
                          openProviderInformation(id, completed);
                        }
                      }}
                      variant="outlined"
                      sx={{
                        backgroundColor: theme.palette.common.white,
                        color: theme.palette.common.black,
                        mx: 1,
                        "&:hover": {
                          backgroundColor: theme.palette.common.white,
                        },
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ marginRight: 5 }}
                        icon={faUserDoctor}
                      />
                      {formatMessage({
                        id: "bulkOrders.programDetails.providerInformation",
                      })}
                    </Button>
                    {FeatureFlag.groupDelete && (
                      <Button
                        disabled={groupOrder.completed}
                        onClick={() => {
                          setGroupOrderID(groupOrder.id);
                          setDeleteModalOpen(true);
                        }}
                        variant="outlined"
                        sx={{
                          backgroundColor: theme.palette.common.white,
                          color: theme.palette.common.black,
                          mx: 1,
                          "&:hover": {
                            backgroundColor: theme.palette.common.white,
                          },
                        }}
                      >
                        <FontAwesomeIcon
                          style={{ marginRight: 5 }}
                          icon={faTrash}
                        />
                        {formatMessage({
                          id: "bulkOrders.programDetails.orderGroup.delete",
                        })}
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ mx: 1 }}
                  >
                    (
                    {groupOrder.states.String.split("|")
                      .sort((a, b) => (a > b ? 1 : -1))
                      .join(", ")}
                    )
                  </Typography>
                  <Button
                    sx={{ margin: 2 }}
                    disabled={
                      groupOrder.completed || !groupOrder.approver_npi.Valid
                    }
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setGroupOrderID(groupOrder.id);
                      setConfirmModalOpen(true);
                    }}
                  >
                    {formatMessage({
                      id: "bulkOrders.programDetails.submitOrders",
                    })}
                  </Button>
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ mx: 1 }}
                  >
                    {groupOrder.completed && groupOrder.submission_date !== null
                      ? formatMessage({
                          id: "bulkOrders.programDetails.SUBMITTED",
                        }) +
                        " - " +
                        formatDate(groupOrder.submission_date.Time)
                      : null}
                  </Typography>
                </Grid>
              </Card>
            ))}
          </Grid>
        </Collapse>
        <OrderGroupModal
          open={open}
          onClose={() => setOpen(false)}
          stateList={stateList}
          clientID={selectedProgram.program_id}
          orderGroupStates={groupOrders.flatMap((groupOrder) =>
            groupOrder.states.String.split("|")
          )}
        />
      </Card>
    </Box>
  );
};

export default ProgramDetails;
