import { Box, LinearProgress } from "@mui/material";
import React from "react";
import Plot from "react-plotly.js";

import { DataGridWrapper } from "../../common/components/DataGrid/DataGrid";
import { useFetchOrderReportQuery } from "../../slices/exactScience/exactScience.service";

const DayReportCols = [
  {
    field: "Year",
    headerName: "Year",
    sortable: true,
    minWidth: 150,
  },
  {
    field: "MonthString",
    headerName: "Month",
    sortable: true,
    minWidth: 150,
  },
  {
    field: "DayOfMonth",
    headerName: "Day",
    sortable: true,
    minWidth: 150,
  },
  {
    field: "Count",
    headerName: "Count",
    sortable: true,
    minWidth: 150,
  },
];

export const OrderDayReport: React.FC = () => {
  const { data: dayReport, isFetching } = useFetchOrderReportQuery("day", {});

  return (
    <Box>
      <Plot
        data={[
          {
            x: dayReport?.map((elem) => elem.Date) ?? [],
            y: dayReport?.map((elem) => elem.Count) ?? [],
            type: "scatter",
            mode: "lines+markers",
            marker: { color: "red" },
          },
        ]}
        layout={{
          width: window.innerWidth - 60,
          height: 400,
          title: "Cologuard Orders Placed With Recuro",
        }}
      />
      {isFetching && (
        <LinearProgress
          sx={{ width: "100%" }}
          variant="query"
        />
      )}
      <DataGridWrapper
        pageSize={10}
        pageNumber={0}
        sortFieldName="count"
        sortDirection="desc"
        loading={isFetching}
        columns={DayReportCols}
        rows={dayReport || []}
      />
    </Box>
  );
};
