type ApplicationFlags = {
  sso: boolean;
  reporting: boolean;
  featuresInDevelopment: boolean;
  bulkOrders: boolean;
  groupDelete: boolean;
};

type Environments = "dev" | "staging" | "prod";

const environment: Environments =
  (process.env.REACT_APP_ENVIRONMENT as Environments) || "prod";

const Flags: Record<Environments, ApplicationFlags> = {
  dev: {
    sso: true,
    reporting: true,
    featuresInDevelopment: true,
    bulkOrders: true,
    groupDelete: true,
  },
  staging: {
    sso: true,
    reporting: true,
    featuresInDevelopment: true,
    bulkOrders: true,
    groupDelete: true,
  },
  prod: {
    sso: false,
    reporting: false,
    featuresInDevelopment: false,
    bulkOrders: true,
    groupDelete: false,
  },
};

export const FeatureFlag = Flags[environment];
export type { ApplicationFlags, Environments };
