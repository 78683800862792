import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import * as React from "react";

import { FollowUpReportRow } from "../../common/resources/reporting.types";
import {
  useFetchFollowUpsQuery,
  useUpdateFollowupMutation,
} from "../../slices/exactScience/exactScience.service";

const Row = (props: {
  row: FollowUpReportRow;
  updateFx: MutationTrigger<any>;
  disableBtn: boolean;
}) => {
  const { row, updateFx, disableBtn } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
        >
          {row.firstName} {row.lastName}
        </TableCell>
        <TableCell align="right">{row.vcaMemberID}</TableCell>
        <TableCell align="center">{row.contactAttemptsMade}</TableCell>
        <TableCell align="center">
          <Button
            variant="contained"
            disabled={disableBtn}
            onClick={() => {
              setOpen(false);
              updateFx({ patientId: row.patientId, orderId: row.orderId });
            }}
          >
            Mark Sent
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
          >
            <Box sx={{ margin: 1 }}>
              <Table
                size="small"
                aria-label="purchases"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Address</TableCell>
                    <TableCell>Apt/Unit</TableCell>
                    <TableCell>City</TableCell>
                    <TableCell>State</TableCell>
                    <TableCell>Zip Code</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={row.patientId}>
                    <TableCell>{row.line}</TableCell>
                    <TableCell>{row.line2 ?? "-"}</TableCell>
                    <TableCell>{row.city}</TableCell>
                    <TableCell>{row.state}</TableCell>
                    <TableCell>{row.zip}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export const FollowUpReport: React.FC = () => {
  const { data: followUpReport, isFetching } = useFetchFollowUpsQuery("report");
  const [updateFollowup, status] = useUpdateFollowupMutation();

  return (
    <Box>
      {(isFetching || status.isLoading) && (
        <LinearProgress
          sx={{ width: "100%" }}
          variant="query"
        />
      )}
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Member</TableCell>
              <TableCell align="right">Member ID</TableCell>
              <TableCell align="right">Outreach Attempts Made</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {followUpReport?.map((row) => (
              <Row
                key={row.orderId}
                row={row}
                updateFx={updateFollowup}
                disableBtn={isFetching || status.isLoading}
              />
            ))}
            {followUpReport?.length === 0 && (
              <TableRow>
                <TableCell align="center">
                  <Typography variant="h6">No Mail To Send</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
