enum ReportType {
  Client = "Client Care Monthly Utilization Report",
  Invoices = "Invoices",
  Missed_Call = "Missed Call Reports",
  Registration = "Registration Reports",
  Service = "Service Data Reports",
  State = "State Reports",
  Utilization = "Utilization Data Reports",
  Utilization_Collection = "Utilization Report Collection(s)",
}

type FetchReportTypes = {
  accessToken: string;
};

type ReportTypeResponse = {
  id: string;
  value: ReportType;
};

type ProviderReportRow = {
  Count: number;
  FirstName: string;
  InPersonVisit: boolean;
  LastName: string;
  NPI: string;
};

type FollowUpReportRow = {
  city: string;
  contactAttemptsMade: number;
  firstName: string;
  lastName: string;
  line: string;
  line2?: string;
  note?: string;
  orderId: string;
  patientId: string;
  state: string;
  vcaMemberID: number;
  zip: string;
};

export { ReportType };

export type {
  FetchReportTypes,
  FollowUpReportRow,
  ProviderReportRow,
  ReportTypeResponse,
};
