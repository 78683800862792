import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import { useAppDispatch } from "../../../app/hooks";
import { updateBenefit } from "../../../slices/benefits/benefitSlice";
import {
  BenefitTemplate,
  CardStyle,
  CardTemplate,
  TemplateStyle,
} from "../../resources/benefits.types";
import { theme } from "../../theme/theme";
import { DropZone } from "../DropZone/DropZone";

export const CardInputCard: React.FC<{
  card: CardTemplate;
  index: number;
  selected: BenefitTemplate;
}> = ({ card, index, selected }) => {
  const dispatch = useAppDispatch();

  const updateCardTextField = (
    cardField: "src" | "header" | "groupSuffix",
    value: string
  ) => {
    const cards = selected.customData.card.map((cardTemplate) => {
      return { ...cardTemplate };
    });
    cards[index][cardField] = value;
    const customData = { ...selected.customData, card: cards };
    dispatch(updateBenefit({ ...selected, customData: customData }));
  };

  const updateCardBooleanField = (
    cardField: "hero" | "hasOverlay",
    value: boolean
  ) => {
    const cards = selected.customData.card.map((cardTemplate) => {
      return { ...cardTemplate };
    });
    cards[index][cardField] = value;
    const customData = { ...selected.customData, card: cards };
    dispatch(updateBenefit({ ...selected, customData: customData }));
  };

  const updateCardStyle = (value: CardStyle) => {
    const cards = selected.customData.card.map((cardTemplate) => {
      return { ...cardTemplate };
    });
    cards[index].style = value;
    const customData = { ...selected.customData, card: cards };
    dispatch(updateBenefit({ ...selected, customData: customData }));
  };

  const removeSection = () => {
    const card = selected.customData.card.map((content) => {
      return { ...content };
    });
    card.splice(index, 1);
    const customData = { ...selected.customData, card: card };
    dispatch(updateBenefit({ ...selected, customData: customData }));
  };

  return (
    <Card
      sx={{ borderRadius: "5pt", marginBottom: 4, padding: 2 }}
      id={`card-input-${index}`}
    >
      <FormControl fullWidth>
        <TextField
          style={{
            margin: 8,
          }}
          multiline
          label="Header Text"
          value={card.header ?? ""}
          onChange={(event: { target: { value: string } }) => {
            updateCardTextField("header", event.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth>
        <DropZone
          fileFormat={["png"]}
          field={`card-${index}`}
          selected={selected}
          key={`${selected.id}-card-${index}-drop`}
        >
          <TextField
            style={{
              margin: 8,
              width: "96%",
            }}
            multiline
            label="Card Image"
            value={card.src ?? ""}
          />
        </DropZone>
      </FormControl>
      {(card.style === CardStyle.Left_Align ||
        card.style === CardStyle.Right_Align) && (
        <FormControl fullWidth>
          <TextField
            style={{
              margin: 8,
            }}
            multiline
            label="Member ID Modifier"
            value={card.groupSuffix ?? ""}
            onChange={(event) => {
              updateCardTextField("groupSuffix", event.target.value);
            }}
          />
        </FormControl>
      )}
      <FormControl
        fullWidth
        sx={{
          width: "98%",
          margin: "4pt 5pt",
        }}
      >
        <InputLabel id="card-style-select-label">Format</InputLabel>
        <Select
          sx={{
            backgroundColor: theme.palette.background.paper,
            textAlign: "start",
          }}
          onChange={(event) => {
            updateCardStyle(event.target.value as CardStyle);
          }}
          labelId="card-style-select-label"
          id="card-style-select"
          value={card.style ? card.style : CardStyle.No_Text}
          label="Card Style"
        >
          {Object.entries(CardStyle).map((style, index) => {
            return (
              <MenuItem
                key={`menu-item-${index}`}
                value={style[1]}
              >
                {style[0].replace("_", " ")}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        {selected.template === TemplateStyle.Hero_Template && (
          <FormControlLabel
            disabled={selected.template !== TemplateStyle.Hero_Template}
            control={
              <Checkbox
                onChange={(event) => {
                  updateCardBooleanField("hero", event.target.checked);
                }}
                checked={card.hero}
              />
            }
            label="Is Hero Card"
          />
        )}
        <Button
          variant="text"
          color="error"
          onClick={() => {
            removeSection();
          }}
        >
          Remove
        </Button>
      </Box>
    </Card>
  );
};
