import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { FormikProps } from "formik";
import { useState } from "react";
import { useIntl } from "react-intl";

import { IFormData } from "../../common/resources/bulkOrders.types";

const CommunicationsConfiguration: React.FC<{
  formik: FormikProps<IFormData>;
  verify: boolean;
  setVerify: (value: boolean) => void;
}> = ({ formik, verify, setVerify }) => {
  const { formatMessage } = useIntl();
  const [sendOutreach, setSendOutreach] = useState<string | null>(null);
  const [sharing, setSharing] = useState<string | null>(null);

  const handleSendOutreach = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: string | null
  ) => {
    setSendOutreach(value);
  };

  const handleSharing = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: string | null
  ) => {
    setSharing(value);
  };

  return (
    <Box>
      <Grid
        item
        sx={{ margin: 2 }}
      >
        <Typography
          sx={{ marginY: 1 }}
          variant="subtitle1"
        >
          {formatMessage({
            id: "bulkOrders.programCreation.communicationsConfigurations",
          })}
        </Typography>
        <Typography
          sx={{ marginY: 1 }}
          variant="body1"
        >
          {formatMessage({ id: "bulkOrders.programCreation.selectSettings" })}
        </Typography>
      </Grid>
      <Grid
        item
        sx={{ margin: 2 }}
      >
        <Grid item>
          <Typography variant="caption">
            {formatMessage({
              id: "bulkOrders.programCreation.initialOutreach",
            })}
          </Typography>
        </Grid>
        <ToggleButtonGroup
          value={sendOutreach}
          exclusive
          onChange={handleSendOutreach}
        >
          <ToggleButton
            value="none"
            name="outreach"
            onClick={() => formik.setFieldValue("send_outreach", false)}
          >
            {formatMessage({ id: "bulkOrders.programCreation.none" })}
          </ToggleButton>
          <ToggleButton
            value="mail"
            name="outreach"
            onClick={() => formik.setFieldValue("send_outreach", true)}
          >
            {formatMessage({ id: "bulkOrders.programCreation.mail" })}
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid
        item
        sx={{ margin: 2 }}
      >
        <Grid item>
          <Typography variant="caption">
            {formatMessage({ id: "bulkOrders.programCreation.sharing" })}
          </Typography>
        </Grid>
        <ToggleButtonGroup
          value={sharing}
          exclusive
          onChange={handleSharing}
        >
          <ToggleButton
            value="none"
            name="sharing"
            onClick={() => formik.setFieldValue("results_outreach", false)}
          >
            {formatMessage({ id: "bulkOrders.programCreation.none" })}
          </ToggleButton>
          <ToggleButton
            value="mail"
            name="sharing"
            onClick={() => formik.setFieldValue("results_outreach", true)}
          >
            {formatMessage({ id: "bulkOrders.programCreation.mail" })}
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid
        container
        sx={{ margin: 2, textTransform: "none" }}
      >
        <Grid container>
          <Typography
            variant="subtitle1"
            sx={{ marginY: 1 }}
          >
            {formatMessage({ id: "bulkOrders.programCreation.review" })}
          </Typography>
        </Grid>
        <FormControlLabel
          name="verify"
          value={verify}
          onClick={() => setVerify(!verify)}
          control={<Checkbox checked={verify} />}
          label={formatMessage({ id: "bulkOrders.programCreation.verify" })}
        />
      </Grid>
    </Box>
  );
};

export default CommunicationsConfiguration;
