import { ComponentsOverrides, createTheme, Theme } from "@mui/material/styles";
import type {} from "@mui/x-data-grid/themeAugmentation";

const MuiDataGridOverride: ComponentsOverrides<Theme>["MuiDataGrid"] = {
  cell: () => ({
    backgroundColor: theme.palette.background.paper,
  }),
  root: () => ({
    backgroundColor: theme.palette.primary.contrastText,
    pl: 1,
  }),
  columnHeader: () => ({
    backgroundColor: theme.palette.primary.contrastText,
  }),
  columnHeaderTitle: () => ({
    whiteSpace: "break-spaces",
    lineHeight: 1,
    textAlign: "center",
    fontWeight: 700,
  }),
  "columnHeader--alignRight": () => ({
    pl: 1,
  }),
  columnHeaderTitleContainer: () => ({
    pl: 1,
  }),
};

export const theme = createTheme({
  palette: {
    common: {
      black: "#272727",
      white: "#FBFBFB",
    },
    benefits: {
      main: "#61BCA4",
      light: "#FBFBFB",
      dark: "#61BCA4",
      contrastText: "#FBFBFB",
    },
    primary: {
      main: "#514BA1",
      light: "#A0ABE7",
      dark: "#242458",
      contrastText: "#F5F6FF",
    },
    secondary: {
      main: "#61BCA4",
      light: "#B9E1D7",
      dark: "#398163",
    },
    text: {
      primary: "#272727",
      secondary: "#686868",
      disabled: "#C4C4C4",
    },
    success: {
      main: "#52A14B",
      light: "#CBE6C9",
      dark: "#285F20",
    },
    error: {
      main: "#DF1001",
      light: "#FFC6B7",
      dark: "#C70000",
    },
    grey: {
      300: "#E7E7E7",
    },
    warning: {
      main: "#ED6C02",
      light: "#FF9800",
      dark: "#E65100",
    },
    info: {
      main: "#69A0D3",
      light: "#C6E1F2",
      dark: "#3C5480",
    },
    divider: "#E7E7E7",
    background: {
      default: "#F6F6F6",
      paper: "#FFFFFF",
    },
  },
  spacing: (factor: number) => `${0.25 * factor}rem`,
  typography: {
    fontFamily: 'Lato, "sans-serif"',
    benefitsHeader: {
      textAlign: "start",
      marginTop: "0.125rem",
      marginBottom: "1rem",
      fontSize: "1rem",
      verticalAlign: "center",
      lineHeight: "1.5rem",
      fontWeight: "bold",
    },
    benefitsHeader2: {
      margin: "0.313rem",
      fontSize: "0.75rem",
      verticalAlign: "center",
      lineHeight: "1.0831rem",
      fontWeight: 600,
      textAlign: "center",
    },
    benefitsBody: {
      margin: 0,
      fontSize: ".75rem",
      lineHeight: "1.125rem",
    },
    body1: {
      fontSize: "1rem",
      lineHeight: "1.6rem",
      fontWeight: 400,
    },
    caption: {
      fontSize: "0.825rem",
      fontWeight: 400,
      lineHeight: "1.485rem",
      lineSpacing: "0.031rem",
    },
    h3: {
      fontSize: "2rem",
      fontWeight: 500,
      lineHeight: "2.4rem",
      letterSpacing: "0.016rem",
    },
    h6: {
      marginTop: "0.125rem",
      marginBottom: "1rem",
      fontSize: "1rem",
      verticalAlign: "center",
      lineHeight: "1.5rem",
      fontWeight: "bold",
      textAlign: "center",
    },
    subtitle1: {
      fontSize: "1.25rem",
      lineHeight: "1.625rem",
      fontWeight: 500,
    },
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: ({ theme }) => ({
          zIndex: theme.zIndex.drawer - 1,
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          minWidth: "3.875rem",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Lato, sans-serif",
          textTransform: "initial",
          fontWeight: "700",
          padding: "12px",
        },
        containedPrimary: {
          boxShadow: "2px 2px 12px -2px rgba(182, 170, 255, 0.8)",
          fontSize: "14px",
        },
        text: ({ theme }) => ({
          fontSize: "16px",
          paddingBottom: 5,
        }),
      },
    },
    MuiToggleButtonGroup: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: ({ theme }) => ({
          "& .MuiToggleButtonGroup-grouped:not(:last-of-type), .MuiToggleButtonGroup-grouped:not(:first-of-type)":
            {
              borderRadius: "10px",
              border: `1px solid ${theme.palette.grey[300]} `,
            },
          "& .MuiToggleButton-root.Mui-selected": {
            border: "2px solid #736DB5",
            backgroundColor: theme.palette.background.paper,
          },
        }),
        grouped: ({ theme }) => ({
          color: theme.palette.common.black,
          margin: "10px",
          textTransform: "none",
          padding: "4px 16px",
          borderRadius: "10px",
          middle: {
            selected: {
              border: "2px solid #736DB5",
              backgroundColor: "pink",
            },
          },
        }),
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: ({ theme }) => ({
          color: theme.palette.primary.main,
        }),
        colorSecondary: ({ theme }) => ({
          color: theme.palette.grey[300],
        }),
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.common.white,
          height: "8px",
        }),
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          height: "8px",
        },
        mark: {
          backgroundColor: "transparent",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          backgroundColor: theme.palette.common.white,
          color: theme.palette.common.black,
          boxShadow: "2px 2px 12px -2px rgba(182, 170, 255, 0.8)",
          border: `1px solid ${theme.palette.primary.main}`,
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.background.paper,
        }),
      },
    },
    MuiDataGrid: {
      styleOverrides: MuiDataGridOverride,
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          background: "none",
        },
      },
    },
  },
  shape: {
    screenWidth: 336,
  },
});
